<template>
  <!-- table -->
  <b-overlay
    :show="loading"
    opacity="0.3"
    variant="transparent"
    spinner-variant="primary"
    spinner-type="border"
    rounded
  >
    <b-table
      :items="trips"
      :fields="fields"
      striped
      responsive
    >
      <template #cell(actions)="data">
        <span class="d-flex justify-content-around">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon rounded-circle"
            @click="data.toggleDetails"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon rounded-circle"
            @click="editTripItem(data.item)"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning"
            class="btn-icon rounded-circle"
            @click="shareTripItem(data.item)"
          >
            <feather-icon icon="Share2Icon" />
          </b-button>
          <b-button
            variant="outline-danger"
            class="btn-icon rounded-circle"
            @click="confirmTripText(data.item)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button> -->
        </span>
      </template>

      <template #row-details="row">
        <b-card>
          <b-table
            responsive
            :items="row.item.offers"
            :fields="offerFields"
          >
            <template #cell(status)="data">
              <b-badge :variant="data.value.color">
                {{ data.value.name }}
              </b-badge>
            </template>

            <template #cell(actions)="data">
              <span class="d-flex justify-content-around">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon rounded-circle"
                  @click="viewOfferItem(data.item.id)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-secondary"
                  class="btn-icon rounded-circle"
                  @click="editOfferItem(data.item.id)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-warning"
                  class="btn-icon rounded-circle"
                  @click="confirmDuplicateOfferText(data.item.id)"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>
                <b-button
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  @click="confirmOfferText(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </span>
            </template>
          </b-table>
        </b-card>
      </template>
    </b-table>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BTable,
  BCard,
  BBadge,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

const OFFER_STATUSES = {
  1: { name: 'Draft', color: 'light-secondary' },
  2: { name: 'Pending Agent Review', color: 'light-danger' },
  3: { name: 'Agent Reviewed', color: 'light-warning' },
  4: { name: 'Pending Payment', color: 'light-info' },
  5: { name: 'Completed', color: 'light-success' },
  6: { name: 'Pay on Vendor', color: 'light-primary' },
}

export default {
  name: 'CustomTrips',
  components: {
    BTable,
    BCard,
    BBadge,
    BOverlay,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    page: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'created_at',
          label: 'Created At',
        },
        {
          key: 'updated_at',
          label: 'Updated At',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      offerFields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'trip.arrival_date',
          label: 'Checking',
        },
        {
          key: 'trip.departure_date',
          label: 'Checkout',
        },
        {
          key: 'guests',
          label: 'Guests',
        },
        {
          key: 'total_price',
          label: 'Total Payout',
        },
        {
          key: 'created_at',
          label: 'Booked At',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'vendor.data.display_name',
          label: 'Vendor',
        },
        {
          key: 'vendor.data.user_email',
          label: 'Vendor Email',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      loading: false,
    }
  },
  computed: {
    customTrips() {
      return this.$store.state.app && this.$store.state.app.customTrips
        ? this.$store.state.app.customTrips
        : []
    },
    currentPage() {
      return this.page ? parseInt(this.page, 10) : 1
    },
    trips() {
      if (!this.customTrips) return []
      return this.customTrips.map(trip => ({
        ...trip,
        offers: trip.offers.map(offer => ({
          id: offer.id,
          ...offer,
          status: this.getOfferStatus(offer),
          guests: offer.trip.adults + offer.trip.children,
          detailsShowing: false,
        })),
      }))
    },
  },
  watch: {
    // eslint-disable-next-line
    "$route.params.page": async function (page) {
      await this.loadData()
    },
  },
  created() {
    this.loadCustomTrips()
  },
  methods: {
    getOfferStatus(offer) {
      return offer.status && OFFER_STATUSES[offer.status]
        ? OFFER_STATUSES[offer.status]
        : ''
    },
    loadCustomTrips() {
      this.$store.dispatch('app/fetchCustomTrips').catch()
    },
    handleChangePage(page) {
      this.$router.push({ name: 'trips', params: { page: `${page}` } })
    },
    editOfferItem(item) {
      this.$router.push({
        name: 'editOffer',
        params: { customOfferId: item },
      })
    },
    viewOfferItem(item) {
      this.$router.push({
        name: 'viewCustomOffer',
        params: { customOfferId: item },
      })
    },
    confirmOfferText(item) {
      const self = this

      this.$swal({
        title: 'Delete trip offer?',
        text: `You are going to delete the trip offer with ID: ${item.id}` /* eslint no-underscore-dangle: 0 */,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await self.$store
            .dispatch('app/deleteCustomTrip', item)
            .then(() => {
              self.loadData()
              self.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Custom trip has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {})
        }
      })
    },
    confirmDuplicateOfferText(item) {
      const self = this

      this.$swal({
        title: 'Duplicate trip offer ?',
        text: `You are going to duplicate the trip offer with ID: ${item}` /* eslint no-underscore-dangle: 0 */,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, duplicate it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await self.$store
            .dispatch('app/duplicateCustomTrip', item)
            .then(() => {
              self.loadData()
              self.$swal({
                icon: 'success',
                title: 'Duplicated!',
                text: 'Custom trip has been duplicated.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {})
        }
      })
    },
    confirmTripText(item) {
      const self = this

      this.$swal({
        title: 'Delete trip?',
        text: `You are going to delete the trip with ID: ${item.id}` /* eslint no-underscore-dangle: 0 */,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await self.$store
            .dispatch('app/deleteCustomTrip', item)
            .then(() => {
              self.loadData()
              self.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Custom trip has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {})
        }
      })
    },
  },
}
</script>

<style lang="scss">
.custom-trips-table {
  th {
    border: 1px solid #3b4253 !important;
    padding: 12px;
    height: 61px;

    span {
      width: 100%;
      display: inline-block;
      cursor: pointer;

      &.triangle {
        display: none;
      }
    }
  }
  td {
    border: none !important;
  }
}
</style>
